import React from "react";

import Grid from "@material-ui/core/Grid";

import { ContextProvider } from "./context";
import { FullscreenSection } from "../../components";

import { Calculator } from "./components";

import { useStyles } from "./styles";

export default props => {
    const classes = useStyles();

    return (
        <FullscreenSection
            id="bananaScale"
            classes={{
                root: classes.background,
            }}
        >
            <ContextProvider>
                <Grid
                    container
                    spacing={2}
                    style={{ maxWidth: 1100, margin: "0 auto", height: "100%" }}
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Calculator />
                    </Grid>
                </Grid>
            </ContextProvider>
        </FullscreenSection>
    );
};
