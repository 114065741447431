import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Plx from 'react-plx';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export function HomeSection(props) {
    const classes = useStyles();

    const contentRef = useRef(null);
    const contentParentRef = useRef(null);
    const [disablePlx, setDisablePlx] = useState(false);
    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
        if (contentRef.current && contentParentRef.current) {
            setParentWidth(contentParentRef.current.offsetWidth);
        }
    }, [contentRef, contentParentRef]);

    useEffect(() => {
        setDisablePlx(window.outerWidth < 980);

        const onResize = () => {
            setDisablePlx(window.outerWidth < 980);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [window.innerHeight]);

    const parallaxData = [
        {
            start: `#container-${props.id}`,
            startOffset: `60vh`,
            duration: '10vh',
            properties: [
                {
                    startValue: props.index === 0 ? 1 : 0,
                    endValue: 1,
                    property: 'opacity',
                },
            ],
        },
        {
            start: `#container-${props.id}`,
            startOffset: `100vh`,
            duration: '10vh',
            properties: [
                {
                    startValue: 1,
                    endValue: 0,
                    property: 'opacity',
                },
            ],
        },
    ];

    return (
        <section id={props.id} className={classNames(classes.section, props.fullWidth ? classes.fullwidthSection : '')}>
            <div className={classes.inner}>
                <Grid container spacing={2}>
                    {!props.fullWidth && (
                        <Grid item xs={6}>
                            <div ref={contentParentRef}>
                                <Plx
                                    ref={contentRef}
                                    disabled={disablePlx}
                                    className={classes.content}
                                    style={{
                                        width: parentWidth,
                                    }}
                                    parallaxData={parallaxData}
                                >
                                    <Typography variant="h2" color="inherit" className={classes.title}>
                                        {props.title}
                                    </Typography>
                                    <Typography paragraph color="inherit">
                                        {props.desc}
                                    </Typography>

                                    {props.to && (
                                        <Button className={classes.button} to={props.to}>
                                            View Project
                                        </Button>
                                    )}
                                </Plx>
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={props.fullWidth ? 12 : 6} className={classes.centerItem}>
                        <div id={`container-${props.id}`} className={classes.container}>
                            {props.component}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

HomeSection.propTypes = {
    classes: PropTypes.object,
};
