import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { useStyles, useMessageStyles } from "./styles";

export const CliMessage = ({ message, type }) => {
    const classes = useMessageStyles();
    return (
        <>
            {type ? <span className={classes[type]}>{type}: </span> : null}
            {message}
        </>
    );
};

CliMessage.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export const CommandLine = ({
    autoFocus,
    commands: _commands,
    styles: _styles = {},
    messages: _messages,
    prompt, // symbol prepended to text
    ...props
}) => {
    const classes = useStyles();

    const editableContentRef = useRef();

    const messages = {
        ..._messages,
        WELCOME_MESSAGE: 'Welcome! Type "help" to see a list of commands.',
        INVALID_COMMAND:
            'Invalid command. Try typing "help" to see a list of supported commands.',
    };

    const commands = {
        help: {
            fn: args => {
                const result = [" ", "Supported commands:"];

                const longest = Math.max(
                    ...Object.keys(_commands).map(el => el.length)
                );

                for (const cmd in _commands) {
                    const desc = _commands[cmd].desc;
                    result.push(
                        ` ${cmd.padEnd(longest + 2, " ")} ${desc ? desc : ""}`
                    );
                }

                result.push(" ");
                return result;
            },
        },
        ..._commands,
    };

    const initialMessage = messages["WELCOME_MESSAGE"].split("\n");
    const [buffer, setBuffer] = useState(initialMessage);

    const [typingAllowed, setTypingAllowed] = useState(true);
    const [lines, setLines] = useState([]);

    React.useEffect(() => {
        const newLines = buffer.map((line, index) => {
            return <p key={index}>{line}</p>;
        });
        setLines(newLines);
    }, [buffer]);

    function setCaretToEnd(target) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
    }

    const focusPrompt = useCallback(() => {
        const el = editableContentRef.current;
        if (!el) return;
        setCaretToEnd(el);
    }, []);

    const appendToBufferArray = useCallback(val => {
        if (typeof val === "string") {
            val = val.split("\n");
        }
        setBuffer(prev => {
            return [prev, val].flat();
        });
    }, []);

    const handleEnter = useCallback(() => {
        appendToBufferArray(`${prompt}${editableContentRef.current.innerText}`);
        const input = editableContentRef.current.innerText.trim();

        const commandNameToRun = /^([^\s]*)\s?.*$/.exec(input).pop();

        // reset the prompt
        editableContentRef.current.innerText = "";

        // execute the command
        if (commandNameToRun === "") {
            return;
        }
        const command = commands[commandNameToRun];
        if (typeof command === "undefined") {
            appendToBufferArray(
                <CliMessage
                    type="error"
                    message={messages["INVALID_COMMAND"]}
                />
            );
            return;
        }

        // Parse out arguments
        const args = input.split(/\s+/).slice(1);
        if (command.isAsync) {
            // block console while async request is running
            setTypingAllowed(false);

            command.fn(args).then(result => {
                setBuffer(prev => [...prev, result]);
                setTypingAllowed(true);
            });
        } else {
            const result = command.fn(args);
            appendToBufferArray(result);
        }
    }, []);

    const handleKeyDown = useCallback(e => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleEnter();
        }
    }, []);

    const handleClickInput = useCallback(e => {
        // prevents the cursor from jumping to end when clicking on input
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return (
        <div
            className={classes.root}
            style={_styles.root}
            onClick={focusPrompt}
        >
            {lines}
            <p
                className={classes.lastLine}
                style={{ display: typingAllowed ? "block" : "none" }}
            >
                <span className={classes.prompt}>{prompt}</span>
                <span
                    spellCheck="false"
                    contentEditable="true"
                    onClick={handleClickInput}
                    onKeyDown={handleKeyDown}
                    ref={editableContentRef}
                    className={classes.input}
                ></span>
                <span className={classes.cursor}> </span>
            </p>
        </div>
    );
};

CommandLine.propTypes = {
    messages: PropTypes.objectOf(PropTypes.string),
    commands: PropTypes.object.isRequired,
    prompt: PropTypes.string,
    autoFocus: PropTypes.bool,
};

CommandLine.defaultProps = {
    // prompt: '> tiagocaetano.app $ ',
    prompt: "> ",
    commands: {},
    messages: {
        WELCOME_MESSAGE: "",
        INVALID_COMMAND: "Invalid command.",
    },
    autoFocus: true,
    styles: {},
};
