import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { planetData } from '../data';

import { Context } from '../context';
import clsx from 'clsx';

import styles from './planetStyles';
const useStyles = makeStyles(styles, { name: 'planet' });

export default props => {
    const classes = useStyles();
    const [hover, setHover] = useState(null);

    const {
        // sunSize,
        // orbitScale,
        // planetScale,
        // earthSize,
        zoom,
    } = useContext(Context);

    const { orbit, distance, diameter, name } = props;

    const earthOrbit = planetData[2].orbit;
    const orbitSpeed = (orbit * 30) / earthOrbit;

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    return (
        <div
            id={props.name}
            className={clsx(classes.orbit, hover ? classes.orbitHover : '')}
            style={{
                width: `${distance}em`,
                height: `${distance}em`,
            }}
        >
            <div
                className={classes.planetPos}
                style={{
                    animationDuration: `${zoom === '+' ? 0 : orbitSpeed}s`,
                }}
            >
                <div
                    className={classNames(classes.planet, classes[props.id])}
                    style={{
                        fontSize: `${diameter}em`,
                        animationDuration: `${zoom === '+' ? 0 : orbitSpeed}s`,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div
                        className={clsx(classes.info, hover ? classes.hoverInfo : '')}
                    >
                        <div className={clsx(classes.infoUI)} />
                        {name}
                    </div>
                </div>
            </div>
        </div>
    );
};
