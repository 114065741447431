import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircle from "@material-ui/icons/AddCircle";

import { useWheelContext } from "../context";
import clsx from "clsx";

const useStyles = nextColor =>
    makeStyles(
        theme => {
            return {
                root: {
                    marginTop: theme.spacing(2),
                },
                disabled: {
                    opacity: 0.5,
                },
                input: {
                    background: "rgba(0,0,0,0.24)",
                    color: `${nextColor} !important`,
                },
                notchedOutline: {
                    borderColor: `${nextColor} !important`,
                },
                label: {
                    color: `${nextColor} !important`,
                },
                helperText: {
                    color: `${nextColor} !important`,
                    display: "flex",
                    justifyContent: "space-between",
                },
            };
        },
        {
            name: "AddName",
        }
    );

export const AddName = props => {
    const location = useLocation();
    let { pathname } = location;

    const { state, actions } = useWheelContext();
    const { availableColors } = state;

    const disabled = !availableColors.length;

    const nextColor = availableColors[0] || "white";
    const classes = useStyles(nextColor)();

    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    const handleAdd = () => {
        actions.personAdd(value);
    };

    const onKeyDown = e => {
        if (error !== "already exists" && e.key === "Enter") {
            handleAdd();
            setValue("");
        } else if (e.key === "Escape") {
            setValue("");
        }
    };

    const handleChange = React.useCallback(
        e => {
            const value = e.target.value;
            setValue(value);
        },
        [state.sections]
    );

    React.useEffect(() => {
        setError(false);

        if (state.sections.length === 0) {
            setError("2 participants needed");
        } else if (state.sections.length === 1) {
            setError("1 more participant needed");
        } else if (disabled) {
            setError("Maxed out");
        }

        state.sections.forEach(section => {
            if (
                section.name.toLowerCase().trim() === value.toLowerCase().trim()
            ) {
                setError("already exists");
            }
        });
    }, [value]);

    return (
        <TextField
            autoFocus={pathname !== "/"}
            fullWidth
            error={!!error}
            disabled={disabled}
            variant="outlined"
            value={value}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            label="add"
            className={clsx(classes.root, disabled ? classes.disabled : "")}
            classes={{
                root: disabled ? classes.disabled : "",
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.label,
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <AddCircle />
                    </InputAdornment>
                ),
                classes: {
                    root: classes.input,
                    notchedOutline: classes.notchedOutline,
                },
            }}
            FormHelperTextProps={{
                classes: {
                    root: classes.helperText,
                },
            }}
            helperText={
                <>
                    <span className={classes.error}>{error}</span>
                    <span className={classes.counter}>{`${
                        state.sections.length
                    }/${state.sections.length + availableColors.length}`}</span>
                </>
            }
        />
    );
};
