import { constants } from "./";
const { minRotation } = constants;

const spinTheWheel = (state, payload) => {
    const newState = { ...state };
    newState.spinning = true;

    // rotate the wheel 5 times
    // then rotate a random amount between 1 - 360,
    var extraRotation = Math.floor(Math.random() * (360 - 1 + 1)) + 1;

    const newRotation = state.rotation + minRotation + extraRotation;

    newState.rotation = newRotation;

    // find which section wins
    const filteredSections = state.sections.filter(
        section => section.hide !== true
    );
    const numSections = filteredSections.length;
    const sectionWidth = 360 / numSections;

    // this number tells us how many sections have gone past the starting point
    const sectionsPastStart = newRotation / sectionWidth;
    // this number tells us how many times the wheel turned (eg. 5.25 means it turned 5 times and a quarter)
    const wheelTurns = sectionsPastStart / numSections;

    // extract only the decimal bit of the number as it will tell us the percentage of the incomplete rotation
    // 1 rotation -> 100
    // X rotation -> decimal
    const decimal = wheelTurns - Math.floor(wheelTurns);
    const landingSection = numSections * decimal;

    const winningSectionN = numSections - 1 - Math.floor(landingSection);

    try {
        newState.winner = filteredSections[winningSectionN];
    } catch (error) {
        newState.winner = false;
    }

    return newState;
};

export const reducer = (state, action) => {
    // console.log(state, action);
    switch (action.type) {
        case "RESET":
            return action.payload;

        case "SPIN":
            return spinTheWheel(state);

        case "STOP_SPINNING":
            return {
                ...state,
                spinning: false,
            };

        case "PERSON_ADD":
            const { availableColors } = state;
            const color = availableColors.splice(0, 1);

            return {
                ...state,
                sections: [
                    ...state.sections,
                    {
                        ...action.payload,
                        id: state.sections.length,
                        color,
                    },
                ],
                availableColors,
            };

        case "CURRENT_HOVER":
            return {
                ...state,
                currentHover: action.payload,
            };

        case "SET_TITLE":
            return {
                ...state,
                title: action.payload,
            };

        case "TOGGLE_SECTION":
            const remove = state.sections.findIndex(
                section => section.id === action.payload
            );
            const section = state.sections[remove];
            section.hide = !section.hide;

            return {
                ...state,
            };

        case "SHARE":
            return state;

        default:
            return state;
    }
};
