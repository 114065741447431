import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { FullscreenSection } from "../../components";

import { useStyles } from "./styles";
import img from "./virgina-mary.png";

export default (props) => {
    const classes = useStyles();

    return (
        <FullscreenSection
            id="virginaMary"
            classes={{
                root: classes.background,
            }}
        >
            <Grid
                container
                spacing={0}
                style={{ maxWidth: 900, margin: '0 auto', height: '100%' }}
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12} md={8}>
                    <Paper elevation={4} className={classes.container}>
                        <img src={img} className={classes.img} />
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className={classes.info}>
                                    <Typography variant="h6" component="h1" gutterBottom>
                                        Virgina Mary
                                    </Typography>

                                    <div className={classes.buttonHolder}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://www.etsy.com/uk/listing/1066312394"
                                        >
                                            Buy it on Etsy
                                        </Button>
                                        {/* <Button
                                            variant="outlined"
                                            color="inherit"
                                        >
                                            Check it on instagram
                                        </Button> */}
                                        <Button href={img} download target="_blank" variant="outlined" color="inherit">
                                            Steal it
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </FullscreenSection>
    );
};
