import React from 'react';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => {
    return {
        '@keyframes turning': {
            from: { transform: 'rotate(360deg)', },
            to: { transform: 'rotate(0)', },
        },
        stars: {
            position: 'fixed',
            top: '-10%',
            left: '-10%',
            width: '120vw',
            height: '120vh',
            margin: 'auto',
            animation: '$turning 1000s infinite linear',
            transformOrigin: '50% 25%',
            opacity: 0.75,
        },
        '@keyframes starBlink': {
            from: {
                transform: 'scale(0.9)',
                opacity: 0.7,
            },
            to: {
                transform: 'scale(1)',
                opacity: 0.9,
            },
        },
        star: {
            position: 'absolute',
            width: '1em',
            height: '1em',
            borderRadius: '50%',
            background: 'currentColor',
            opacity: 0.5,
            // background: tinycolor.mix(colors.$gold, '#fff', 70),
            boxShadow: `0 0 0.5em 1px currentColor`,
            animation: '$starBlink 0.5s infinite ease alternate',
        },
    }
}


const Stars = (props) => {
    
    const customRandom = ({min, max, power, negative}) => {
        // https://stackoverflow.com/a/4421297/3680559
        // smaller power produces more big numbers
        // higher power produces more small numbers
        let random = Math.floor(min + (max - min) * (Math.pow(Math.random(), power)));
        
        if ( negative ) {
            random = Math.random() > 0.5 ? random : random * -1;
        }
        return random;
    }
    
    const { classes } = props;
    
    const colors = [
        '#FFEDAF',
        '#fbc6b0',
        '#ff9090',
        '#FFFFFF',
        '#f7b8e0',
        '#d2ffed',
    ];

    const numStars = 100;
    // generate an array of X length

    const starsArray = Array.apply(null, { length: numStars });
    const startRotationSpeed = "1500s";

    const newStarStyle = React.useCallback(() => {
        const starSize = customRandom({min: 1, max: 5, power: 3, negative: false});
        const offsetX = customRandom({
            min: Math.random() * 5,
            max: 100,
            power: 1,
            negative: false
        });
        const offsetY = customRandom({
            min: Math.random() * 5,
            max: 100,
            power: 1,
            negative: false
        });
        // different twinkle, in ms so that stars don't appear to blink at same time
        const twinkleDuration = customRandom({min: 1000, max: 3000, power: 1, negative: false});
        const starColor = customRandom({
            min: 0,
            max: colors.length,
            power: 3,
        });
        return {
            fontSize: starSize,
            top: `${offsetY}%`,
            left: `${offsetX}%`,
            animationDuration: `${twinkleDuration}ms`,
            color: colors[starColor],
        }
    })
    
    return (
        <div
            className={classes.stars}
            style={{
                animationDuration: startRotationSpeed
            }}
            >
            {starsArray.map( (e, i) => {
                
                
                return (
                    <div
                        key={i}
                        className={classes.star}
                        style={newStarStyle()}
                        />
                );
            })}
        </div>
    );
};


export default withStyles(styles)(Stars);
