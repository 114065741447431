import React, { useState, useEffect, useCallback } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditRounded from "@material-ui/icons/EditRounded";
import { makeStyles } from "@material-ui/styles";

import { useWheelContext } from "../context";
import { Settings } from "./";

export const useStyles = makeStyles(
    theme => ({
        root: {
            flexGrow: 1,
            background: "transparent",
            color: "white",
            boxShadow: "none",
        },

        titleRoot: {
            background: "transparent",
            fontSize: "1.3125rem",
            fontWeight: 600,
            color: "white",
            "&:hover, &$inputFocused": {
                "&>$icon": {
                    display: "flex",
                },
            },
        },
        input: {
            textOverflow: "ellipsis",
        },
        inputFocused: {},
        underline: {
            "&:before": {
                borderBottom: "2px none transparent",
                transition: theme.transitions.create(["all"], {
                    duration: theme.transitions.duration.simple,
                }),
            },
            "&:hover:not($disabled):before": {
                borderBottom: `2px dotted rgba(255,255,255,0.24)`,
            },
            "&:after": {
                borderBottom: `2px solid ${theme.palette.secondary.main}`,
            },
        },
        disabled: {},
        icon: {
            display: "none",
        },
    }),
    {
        name: "Title",
    }
);

export const Title = props => {
    const classes = useStyles();
    const { state, actions } = useWheelContext();
    const { title } = state;

    const [initialValue, setInitialValue] = useState(title);
    const [inputValue, setInputValue] = useState(initialValue);

    useEffect(() => {
        setInitialValue(title);
        setInputValue(title);
        document.title = title;
    }, [title]);

    const saveProjectTitle = useCallback(() => {
        const trimmedValue = inputValue.trim();
        setInputValue(trimmedValue);
        actions.setTitle(trimmedValue);
    }, [inputValue, actions]);

    const handleChange = event => {
        const { value } = event.target;
        setInputValue(value);
    };

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            saveProjectTitle();
        } else if (event.key === "Escape") {
            setInputValue(title);
        }
    };

    const handleBlur = event => {
        const value = event.target.value.trim();

        if (!value) {
            setInputValue(initialValue);
            return;
        }

        saveProjectTitle();
    };

    return (
        <AppBar position="relative" color="inherit" className={classes.root}>
            <Toolbar>
                <FormControl fullWidth className={classes.formControl}>
                    <Input
                        label="test"
                        classes={{
                            root: classes.titleRoot,
                            underline: classes.underline,
                            input: classes.input,
                            disabled: classes.disabled,
                            focused: classes.inputFocused,
                        }}
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                className={classes.icon}
                            >
                                <EditRounded />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Settings />
            </Toolbar>
        </AppBar>
    );
};
