import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { PAGES } from '../../config/pages';
import { useStyles } from './styles';

export const Homepage = props => {
    const classes = useStyles();
    const isMobile = /Mobi|Android/i.test(navigator.userAgent) ? true : false;
    const containerRef = React.useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [hasComboed, setHasComboed] = React.useState(props.hasComboed);
    const [comboPosition, setComboPosition] = React.useState(0);
    const combo = React.useMemo(() => {
        return [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    }, []);

    const iconMap = {
        38: <ArrowUpwardIcon />,
        40: <ArrowDownwardIcon />,
        37: <ArrowBackIcon />,
        39: <ArrowForwardIcon />,
        66: 'B',
        65: 'A',
    };

    const open = Boolean(anchorEl || hasComboed);

    const handleCombo = React.useCallback(
        event => {
            if (hasComboed) {
                return;
            }
            if (event.which === combo[comboPosition]) {
                setComboPosition(prev => {
                    return prev + 1;
                });
            } else {
                setComboPosition(0);
            }
        },
        [combo, comboPosition, hasComboed]
    );

    React.useEffect(() => {
        if (comboPosition === combo.length) {
            setHasComboed(true);
        }
    }, [comboPosition, combo]);

    React.useEffect(() => {
        window.addEventListener('keyup', handleCombo);

        return () => {
            window.removeEventListener('keyup', handleCombo);
        };
    }, [handleCombo]);

    const handleSkip = React.useCallback(() => {
        setHasComboed(true);
    }, []);

    React.useEffect(() => {
        if (hasComboed) {
            props.handleCombo(true);
        }
    }, [props, hasComboed]);

    const showComboBox = !isMobile && !hasComboed;

    return (
        <div className={classes.root}>
            <div className={classes.buttonWrapper} ref={containerRef}>
                {isMobile && (
                    <Button
                        id="trigger-button"
                        aria-controls={open ? 'pages-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        See some stuff
                    </Button>
                )}
                {showComboBox && (
                    <div className={classes.combo}>
                        <Typography variant="overline">Prove your worth</Typography>
                        <ul className={classes.comboList}>
                            {combo.map((key, i) => (
                                <li
                                    key={i}
                                    className={clsx(
                                        classes.comboItem,
                                        i < comboPosition ? classes.comboItemActive : ''
                                    )}
                                >
                                    <Avatar
                                        className={clsx(classes.avatar, i < comboPosition ? classes.avatarActive : '')}
                                    >
                                        {iconMap[key]}
                                    </Avatar>
                                </li>
                            ))}
                        </ul>
                        <Button color="default" className={classes.skipButton} onClick={handleSkip}>
                            Skip
                        </Button>
                    </div>
                )}

                <Menu
                    id="pages-menu"
                    anchorEl={containerRef.current}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'trigger-button',
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                    getContentAnchorEl={null}
                >
                    {Object.keys(PAGES).map(k => {
                        return (
                            <MenuItem key={PAGES[k]} component={Link} to={PAGES[k]}>
                                {PAGES[k]}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        </div>
    );
};

Homepage.propTypes = {
    hasComboed: PropTypes.bool.isRequired,
    handleCombo: PropTypes.func.isRequired,
};