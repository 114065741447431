import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    softBorder: "1px solid rgba(0,0,0,0.12)",
    contentWidth: 1100,
    palette: {
        primary: {
            main: "#E61B63",
        },
        charcoal: "#1D252D",
    },
    no_highlights: {
        WebkitTapHighlightColor: 'transparent',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        MsUserSelect: 'none',
        userSelect: 'none',
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
            },
            label: {
                fontWeight: 600,
            },
        },
    },
});

export default theme;
