import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

import Chip from '@material-ui/core/Chip';

import {
    useWheelContext,
    constants,
} from '../context';

import {
    Section,
    Spin,
} from './'

const { rotationDuration } = constants;

export const WheelContent = (props) => {

    const classes = useStyles();
    const widthRef = useRef(null);
    const [size, setSize] = useState(null);

    const { state } = useWheelContext();

    const {
        rotation,
        sections,
    } = state;

    
    useEffect(() => {
        function _setSize() {
            if (widthRef.current) {
                setSize(widthRef.current.clientWidth);
            }
        }
        
        _setSize()

        const onResize = () => {
            _setSize();
        }

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
        
    }, [widthRef]);

    const filteredSections = sections.filter(section => section.hide !== true)

    return (
        <div className={classes.root}>
            <div ref={widthRef}>

                <div className={classes.wheel} style={{fontSize: size}}>
                    <div
                        className={classes.wheelInner}
                        style={{
                            transition: state.spinning ? `all ${rotationDuration/1000}s cubic-bezier(0,.99,.44,.99)` : 'none',
                            transform: `rotate(${rotation}deg)`
                        }}>
                        {filteredSections.map((section, i) => {
                            return <Section
                                key={section.id}
                                i={i}
                                numSections={filteredSections.length}
                                {...section}

                            />
                        })}
                    </div>
                    <Spin />
                </div>
            </div>
            
            <Chip
                label={state.winner ? `WINNER: ${state.winner.name}` : false}
                className={clsx(
                    classes.chip,
                    classes.chipWinner,
                    state.spinning ? classes.hide : classes.chipWinnerShow,
                )}
                variant="outlined"
                style={{
                    borderColor: state.winner ? state.winner.color : 'transparent',
                    color: state.winner.color,
                    opacity: !state.winner || state.spinning ? 0 : 1,
                }}
            />
        </div>
    );
}