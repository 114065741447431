import React from "react";

import { Redirect, Switch, Route, useLocation } from "react-router-dom";

import { Header, HomeSection } from "../components";

import { PAGES } from "../config/pages";
import { Homepage } from "../pages";

import {
    // RetroDrive,
    Technicolor,
    RainbowFlower,
    Squares,
    SolarSystem,
    WheelOfFortune,
    BananaScale,
} from "../codepens";

import {
    VirginaMary
} from "../etsy";

const codepens = [
    {
        id: "randomizer",
        route: PAGES.randomizer,
        title: "Wheel of Fortune",
        component: <WheelOfFortune />,
        fullWidth: true,
    },
    {
        id: "solarSystem",
        route: PAGES.solarSystem,
        title: "Solar System",
        desc: "",
        component: <SolarSystem />,
        fullWidth: true,
    },
    {
        id: "bubbles",
        route: PAGES.bubbles,
        title: "Bubbles",
        desc:
            "Interactive bubbles that change size as you hover or click them.",
        component: <Squares />,
    },
    {
        id: "rainbowPetals",
        route: PAGES.rainbowPetals,
        title: "Rainbow Petals",
        desc:
            "Interactive component build in React. Try hovering / clicking and changing the settings. ",
        component: <RainbowFlower />,
    },
    {
        id: "stairway",
        route: PAGES.stairway,
        title: "Metamorphosis",
        desc: "Lorem ipsum dolor sit amet.",
        component: <Technicolor />,
    },
    {
        id: "bananaScale",
        route: PAGES.bananaScale,
        title: "Banana for scale",
        component: <BananaScale />,
    },
    {
        id: "virginaMary",
        title: "Virgina Mary",
        component: <VirginaMary />,
    },
];

const MyRoute = props => {
    React.useEffect(() => {
        document.title = props.title || "Free To Steal";
    }, []);
    return <Route {...props}>{props.children}</Route>;
};

export function Routes() {
    const location = useLocation();
    const currentPage = codepens.find(pen => pen.route === location.pathname);
    const hideHeader = currentPage && currentPage.fullWidth;
    const [hasComboed, setHasComboed] = React.useState(false);

    const handleCombo = (value) => setHasComboed(value)

    return (
        <>
            <Header hide={hideHeader} />
            <Switch>
                <MyRoute exact path="/">
                    <HomeSection id="home" fullWidth component={<Homepage handleCombo={handleCombo} hasComboed={hasComboed} />} />
                </MyRoute>

                <MyRoute exact path="/art/virgina-mary" title="Virgina Mary">
                    <VirginaMary />
                </MyRoute>

                {codepens.map(pen => {
                    if (!pen.route) return null;
                    return (
                        <MyRoute
                            key={pen.id}
                            path={`/${pen.route}`}
                            title={pen.title}
                        >
                            {pen.component}
                        </MyRoute>
                    );
                })}

                <Route path="/" render={() => <Redirect to="/" />} />
            </Switch>
        </>
    );
}
