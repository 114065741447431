import React from 'react';
import { useStyles } from './styles';

import { useWheelContext } from '../context';

export const Spin = props => {
    const classes = useStyles();
    const { state, actions } = useWheelContext();

    const handleClick = () => {
        if (
            state.spinning
            || state.sections.length < 2
        ) return;
        actions.spin()
    }

    return (
        <div
            className={classes.spin}
            onClick={handleClick}>
            <span className={classes.spinText}>
                Spin
            </span>
            <span className={classes.spinCircle} >
                <span className={classes.spinCircleShadow} />
            </span>
            
        </div>
    )
}