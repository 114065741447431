import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';

import { sunData, planetData } from './data';

export const Context = React.createContext();

export const ContextProvider = props => {
    const orbitScale = 0.1;

    const [renderData, setRenderData] = useState([]);

    const [zoom, setZoom] = useState('-');
    const [scaleMode, setScaleMode] = useState('none');

    const [planetScale, setPlanetScale] = useState(0.4);

    const [availableSpace, setAvailableSpace] = useState(Math.min(window.innerHeight, window.innerWidth));
    const [scale, setScale] = useState(0);
    const [sunSize, setSunSize] = useState(0);
    const [sunScale, setSunScale] = useState(1); // a special scale just for the sun

    useEffect(() => {
        const onResize = () => {
            setAvailableSpace(window.innerWidth * 2);
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [window.innerHeight, window.innerWidth]);

    useEffect(() => {
        if (renderData.length) {
            setScale(Math.min(availableSpace / (planetData[8].distance * orbitScale), 1)); // make everything smaller so it can fit
        }
    }, [availableSpace, renderData, orbitScale]);

    useEffect(() => {
        const sunSize = Math.round(sunData.diameter * sunScale);

        setSunSize(sunSize);
    }, [sunScale]);

    useEffect(() => {
        switch (scaleMode) {
            case 'size':
                setSunScale(0.1);
                setPlanetScale(0.2);
                break;
            case 'distance':
                setSunScale(0.025);
                setPlanetScale(0.05);
                break;
            default:
                setSunScale(0.05);
                setPlanetScale(0.75);
                break;
        }
    }, [scaleMode]);

    useEffect(() => {
        const sunBuffer = sunSize * 1.25;
        const plutoDistance = planetData[8].distance * orbitScale;
        const remainingSpace = plutoDistance - sunBuffer;

        let sum = 0;
        const planetSum = planetData.reduce((acc, planet) => acc + planet.diameter * planetScale, 0);

        function customAverage({ target, multiplier, value, mustBeOdd = false }) {
            // brings VALUE closer to TARGET using a MULTIPLIER
            let result = Math.ceil(target + multiplier * (value - target));
            if (mustBeOdd && result % 2 !== 0) {
                result += 1;
            }
            return result;
        }

        function adjustDiameter({ diameter }) {
            const _diameter = diameter * planetScale;
            if (scaleMode === 'size') {
                return Math.max(_diameter, 1);
            } else if (scaleMode === 'distance') {
                return customAverage({
                    target: 2,
                    multiplier: 1 / 10,
                    value: _diameter,
                    mustBeOdd: true,
                });
            } else {
                //if (scaleMode === 'none')
                return (remainingSpace / 9 / 2) * 0.5;

                // return customAverage({
                //     target: planetData[2].diameter * planetScale,
                //     multiplier: 1 / 10,
                //     value: _diameter,
                //     mustBeOdd: true
                // })
            }
        }

        function adjustDistance({ distance, diameter }, index) {
            const _orbit = distance * orbitScale;
            const _size = diameter * planetScale;

            if (scaleMode === 'distance') {
                return sunBuffer + _orbit;
            } else if (scaleMode === 'size') {
                const ratio = (remainingSpace * _size) / planetSum;
                sum = sum + ratio;

                const leMath = sunBuffer + sum - (ratio / 2 - _size / 2);

                return customAverage({
                    target: sunBuffer + ((plutoDistance - sunBuffer) / 9) * (index + 1),
                    multiplier: 1,
                    value: leMath,
                });
            } else {
                // if (scaleMode === 'none')
                return sunBuffer + (remainingSpace / 9) * (index + 1);

                // const ratio = remainingSpace * _size / planetSum
                // sum = sum + ratio
                // const leMath = sunBuffer + sum - (ratio / 2 - _size / 2)
                // return customAverage({
                //     target: sunBuffer + (((plutoDistance - sunBuffer) / 9 )* (index + 1)),
                //     multiplier: 2 / 6,
                //     value: leMath,
                // })
            }
        }

        const newPlanetData = planetData.map((planet, index) => {
            return {
                ...planet,
                diameter: adjustDiameter(planet),
                distance: adjustDistance(planet, index),
            };
        });

        setRenderData(newPlanetData);
    }, [scaleMode, sunSize, planetData, orbitScale]);

    return (
        <Context.Provider
            value={{
                renderData,
                scaleMode,
                setScaleMode,
                availableSpace,
                scale,
                sunSize,
                sunScale,
                planetScale,
                orbitScale,
                zoom,
                setZoom,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export const useContextHook = () => {
    const context = useContext(Context);
    return context;
};

export const ContextWrapper = ({ children, ...rest }) => {
    return <ContextProvider rest>{children}</ContextProvider>;
};

export const ContextConsumer = Context.Consumer;
