import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
    theme => ({
        background: {
            position: 'relative',
            background: '#ff829e',
            zIndex: 2000,
            overflow: 'auto',
        },
        img: {
            position: 'relative',
            maxWidth: '100%',
            display: 'block',
        },
        container: {
            borderRadius: theme.spacing(1),
            overflow: 'hidden',
            background: 'none',
        },
        info: {
            padding: theme.spacing(4),
            background: 'white',
        },
        buttonHolder: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(0, -1),
            '&>*': {
                margin: theme.spacing(1),
            },
        },
    }),
    {
        name: 'BananaScale',
    }
);
