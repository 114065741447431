import React from "react";

import { useWheelContext } from "../context";

import { CustomChip, AddName } from "./";

export const PeopleList = props => {
    const { state } = useWheelContext();

    const { sections } = state;

    return (
        <div>
            <div>
                {sections.map(section => {
                    return <CustomChip key={section.name} {...section} />;
                })}
            </div>
            <div>
                <AddName />
            </div>
        </div>
    );
};
