import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Grid from "@material-ui/core/Grid";

import { useStyles } from "./styles";

export const FullscreenSection = ({
    id,
    children,
    fullWidth,
    classes: _classes,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <section
            id={`Section-${id}`}
            className={classNames(
                classes.section,classes.fullwidthSection,
                _classes.root
            )}
        >
            <div className={classes.inner}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.centerItem}>
                        <div
                            id={`container-${id}`}
                            className={classes.container}
                        >
                            {children}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

FullscreenSection.defaultProps = {
    classes: {},
};

FullscreenSection.propTypes = {
    classes: PropTypes.object,
};
