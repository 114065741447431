import tinycolor from 'tinycolor2';

export default theme => {
    const planetColors = {
        mercury: '#D4D4D4',
        venus: '#f9963b',
        earth: '#3370da',
        mars: '#fe6a00',
        jupiter: '#FEFEDF',
        saturn: '#e2d39c',
        uranus: '#36a0ca',
        neptune: '#437ade',
        pluto: '#d79271',
        moon: '#e5dcb3',
        asteroid: '#b3a1a1',
    };

    return {
        '@keyframes orbit': {
            from: { transform: 'rotate(0)' },
            to: { transform: 'rotate(360deg)' },
        },
        orbit: {
            position: 'absolute',
            border: `1px solid rgba(255,255,255, 0.12)`,
            borderRadius: '50%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            transition: 'all 0.2s ease-in-out',
            pointerEvents: 'none',
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.simple,
            }),
        },
        orbitHover: {
            borderColor: 'rgba(255,255,255, 0.75)',
        },
        planetPos: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            animation: '$orbit 10s infinite linear',
        },
        planet: {
            position: 'absolute',
            fontSize: '1em',
            background: 'white',
            borderRadius: '50%',
            width: '1em',
            height: '1em',
            top: '50%',
            left: '100%',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            animation: '$inverTorbit 10s infinite linear',
            pointerEvents: 'all',
            transform: 'translate(-50%, -50%)',

            '&:before': {
                content: "''",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: 20,
                minHeight: 20,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                zIndex: -1,
            },
        },
        '@keyframes inverTorbit': {
            '0%': { transform: 'translate(-50%) rotate(360deg)' },
            '100%': { transform: 'translate(-50%) rotate(0deg)' },
        },
        info: {
            position: 'absolute',
            top: '100%',
            left: '50%',
            visibility: 'hidden',
            zIndex: 10,
            fontSize: 12,
            color: 'white',
            fontFamily: 'Open Sans, sans-serif',
            background: 'rgba(0,0,0,0.12)',
            whiteSpace: 'nowrap',
            transform: 'translate(-50%, 0)',
            padding: theme.spacing(1),
            textTransform: 'uppercase',
            fontFamily: 'Nunito, Roboto, Helvetica, Arial, sans-serif',
            letterSpacing: 0.5,
            opacity: 0,
            visibility: 'hidden',
            transform: 'translate(-50%, 10px)',
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.simple,
            }),
        },
        hoverInfo: {
            visibility: 'visible',
            transform: 'translate(-50%, 5px)',
            opacity: 1,
        },
        infoUI: {},

        // INDIVIDUAL PLANETS
        mercury: {
            backgroundColor: planetColors.mercury,
        },
        venus: {
            backgroundColor: planetColors.venus,
        },
        earth: {
            backgroundColor: planetColors.earth,
        },
        mars: {
            backgroundColor: planetColors.mars,
        },
        jupiter: {
            backgroundColor: planetColors.jupiter,
            // background: `linear-gradient(${[
            //     '#FEFEDF 0%',
            //     '#FEFEDF 10%',
            //     '#FF9C58 10%',
            //     '#FF9C58 20%',
            //     '#FEFEDF 20%',
            //     '#FEFEDF 30%',
            //     '#FF9C58 30%',
            //     '#FF9C58 50%',
            //     '#FEE894 50%',
            //     '#FEE894 60%',
            //     '#FEFEDF 60%',
            //     '#FEFEDF 75%',
            //     '#FF9C58 75%',
            //     '#FF9C58 80%',
            //     '#FEFEDF 80%',
            //     '#FEFEDF 100%',
            // ].join(',')})`,
        },
        saturn: {
            backgroundColor: planetColors.saturn,
        },
        uranus: {
            backgroundColor: planetColors.uranus,
        },
        neptune: {
            backgroundColor: planetColors.neptune,
        },
        pluto: {
            backgroundColor: planetColors.pluto,
        },
        moon: {
            backgroundColor: planetColors.moon,
        },
        asteroid: {
            backgroundColor: planetColors.asteroid,
        },
    };
};
