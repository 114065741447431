// <3 NASA - https://nssdc.gsfc.nasa.gov/planetary/planetfact.html

export const sunData = {
    diameter: 1392.000, // (10^3 km)
}
// $sun: 40px;
// $mercury - orb : 70px; $mercury - pl: 4px;
// $venus - orb : 100px; $venus - pl: 8px;
// $earth - orb : 145px; $earth - pl: 6px;
// $mars - orb : 190px; $mars - pl: 6px;
// $jupiter - orb : 340px; $jupiter - pl: 18px;
// $saturn - orb : 440px; $saturn - pl: 12px;
// $uranus - orb : 520px; $uranus - pl: 10px;
// $neptune - orb : 630px; $neptune - pl: 10px;
// $pluto - orb : 780px; $pluto - pl: 3px;

// $asteroids - belt - orb : 300px; $asteroids - belt - pl: 210px;

export const planetData = [
    {
        id: 'mercury',
        name: 'Mercury',
        orbit: 87.968, // duration in days
        ratio: 4.2, // orbit duration ratio compared to earth
        distance: 57.9, // distance from the sun (10^6 km)
        diameter: 4.879, // diameter (10^3 km)
    },{
        id: 'venus',
        name: 'Venus',
        orbit: 224.695,
        ratio: 1.6,
        distance: 108.2,
        diameter: 12.104,
    },{
        id: 'earth',
        name: 'Earth',
        orbit: 365.242,
        ratio: 1,
        distance: 149.6,
        diameter: 12.756,
        moons: [{
            distance: 0.384,
            diameter: 3.475,
            orbit: 27.3217,
        }],
    },{
        id: 'mars',
        name: 'Mars',
        orbit: 686.973,
        ratio: 0.532,
        distance: 227.9,
        diameter: 6.792,
        moons: 2,
    },{
        id: 'jupiter',
        name: 'Jupiter',
        orbit: 4330.60,
        ratio: 0.084,
        distance: 778.6,
        diameter: 142.984,
        moons: 79,
    },{
        id: 'saturn',
        name: 'Saturn',
        orbit: 10746.94,
        ratio: 0.034,
        distance: 1433.5,
        diameter: 120.536,
        moons: 62,
    },{
        id: 'uranus',
        name: 'Uranus',
        orbit: 30588.74,
        ratio: 0.012,
        distance: 2872.5,
        diameter: 51.118,
        moons: 27,
    },{
        id: 'neptune',
        name: 'Neptune',
        orbit: 59799.90,
        ratio: 0.006,
        distance: 4495.1,
        diameter: 49.528,
        moons: 14,
    },{
        id: 'pluto',
        name: 'Pluto', // never forget
        orbit: 90560,
        ratio: 0.004,
        distance: 5906.4,
        diameter: 2.370,
        moons: 5,
    },
];

export const scaleData = {
    real: {
        sun: {
            diameter: 1392000, // (km)
        },
        planets: [
            {
                id: 'mercury',
                name: 'Mercury',
                orbit: 87.968, // duration in days
                ratio: 4.2, // orbit duration ratio compared to earth
                distance: 57.9, // distance from the sun (10^6 km)
                diameter: 4.879, // diameter in km
            }, {
                id: 'venus',
                name: 'Venus',
                orbit: 224.695,
                ratio: 1.6,
                distance: 108.2,
                diameter: 12.104,
            }, {
                id: 'earth',
                name: 'Earth',
                orbit: 365.242,
                ratio: 1,
                distance: 149.6,
                diameter: 12.756,
                moons: [{
                    distance: 0.384,
                    diameter: 3.475,
                    orbit: 27.3217,
                }],
            }, {
                id: 'mars',
                name: 'Mars',
                orbit: 686.973,
                ratio: 0.532,
                distance: 227.9,
                diameter: 6.792,
                moons: 2,
            }, {
                id: 'jupiter',
                name: 'Jupiter',
                orbit: 4330.60,
                ratio: 0.084,
                distance: 778.6,
                diameter: 142.984,
                moons: 79,
            }, {
                id: 'saturn',
                name: 'Saturn',
                orbit: 10746.94,
                ratio: 0.034,
                distance: 1433.5,
                diameter: 120.536,
                moons: 62,
            }, {
                id: 'uranus',
                name: 'Uranus',
                orbit: 30588.74,
                ratio: 0.012,
                distance: 2872.5,
                diameter: 51.118,
                moons: 27,
            }, {
                id: 'neptune',
                name: 'Neptune',
                orbit: 59799.90,
                ratio: 0.006,
                distance: 4495.1,
                diameter: 49.528,
                moons: 14,
            }, {
                id: 'pluto',
                name: 'Pluto', // never forget
                orbit: 90560,
                ratio: 0.004,
                distance: 5906.4,
                diameter: 2.370,
                moons: 5,
            },
        ]
    }
}