import React, { useState, useEffect, useRef } from 'react';

import { FullscreenSection } from '../../components/FullscreenSection';
import { withStyles } from '@material-ui/core/styles';

const animDuration = 2000;

const styles = theme => {
    return {
        root: {
            // height: '100%',
            width: '50%',
            margin: 0,
            fontFamily: 'Sans-serif',
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 1,
            color: '#666',
        },

        '@keyframes fadeTech': {
            from: { opacity: 0.2 },
            to: { opacity: 0 },
        },
        '@keyframes rotateTech': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' },
        },

        stairway: {
            width: '100%',

            '&:before': {
                content: '""',
                display: 'block',
                paddingBottom: '100%',
            },
        },

        step: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) 0',
            opacity: 1,
            boxShadow: `inset 0 0 0 1px rgba(255,255,255,0.12)`,
            transition: `all ${animDuration}ms linear`,
        },
    };
};

const _Stairway = props => {
    const [sizes, setSizes] = useState([]);
    const [ratio, setRatio] = useState(0);
    const [time, setTime] = useState(0);
    const [aarray, setAarray] = useState([]);

    const { parentRef, animState } = props;

    useEffect(() => {
        let _sizes = [];

        let initialWidth = parentRef.current.offsetWidth;
        let numSizes = 0;
        let _array = [];
        while (initialWidth > 4) {
            _sizes.push(initialWidth);
            _array.push(numSizes);
            numSizes = numSizes + 1;
            initialWidth *= 0.863; // magic number
        }
        setSizes(_sizes);
        setRatio(360 / numSizes / 6);
        setAarray(_array);
    }, [parentRef]);

    useEffect(() => {
        const changeArray = () => {
            if (!document.hidden) {
                let copy = new Array(...aarray);
                copy.push(copy[copy.length - 1] === aarray.length ? 0 : copy[copy.length - 1] + 1);
                copy.splice(0, 1);
                if (copy.length === aarray.length) {
                    setAarray(copy);
                    setTime(time + 1 * animState);
                }
            }
        };

        const colorTimeout = setInterval(changeArray, animDuration);

        return () => {
            clearInterval(colorTimeout);
        };
    }, [aarray, time]);

    const { classes } = props;

    return (
        <div className={classes.stairway}>
            {aarray.map((step, i) => {
                return (
                    <div
                        key={step}
                        id={step}
                        className={classes.step}
                        style={{
                            width: sizes[i],
                            height: sizes[i],
                            background: `hsl(${ratio * i}, 90%, 40%)`,
                            transform: `translate(-50%,-50%) rotate(${45 + time * step + 10 * time}deg)`,
                            opacity: i * 0.1 < 0.5 ? i * 0.1 : 0.5,
                        }}
                    />
                );
            })}
        </div>
    );
};
const Stairway = withStyles(styles)(_Stairway);

const Technicolor = props => {
    const parentRef = useRef(null);
    const animState = 1;

    const { classes } = props;

    return (
        <FullscreenSection id="Technicolor">
            <div id="Technicolor" className={classes.root}>
                <div id={props.id} className={classes.root} ref={parentRef}>
                    <Stairway parentRef={parentRef} animState={animState} />
                </div>
            </div>
        </FullscreenSection>
    );
};

export default withStyles(styles)(Technicolor);
