import React from 'react';
import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import { useWheelContext } from '../context';
import { useStyles } from './styles';

export const CustomChip = props => {
    const { state, actions } = useWheelContext();
    const classes = useStyles();

    const handleDelete = () => {
        if (state.spinning) return;
        actions.toggleSection(props.id)
    }

    const disabled = props.hide;

    return (
        <Chip
            label={props.name}
            onDelete={handleDelete}
            classes={{
                root: clsx(
                    classes.chip,
                    disabled ? classes.chipDisabled : ''
                ),
                avatar: classes.chipIcon,
                deleteIcon: classes.deleteIcon,
            }}
            deleteIcon={disabled ? <ClearIcon /> : <DoneIcon />}
            variant="outlined"
            style={{
                borderColor: props.color,
                color: props.color,
            }}
            onClick={handleDelete}
        />
    )
}