import { makeStyles } from "@material-ui/styles";
import texture from "../../images/texture.png";

export const useStyles = makeStyles(
    theme => ({
        section: {
            backgroundImage: `url(${texture})`,
            backgroundColor: theme.palette.charcoal,
            backgroundAttachment: 'fixed',
            overflow: 'hidden',
        },
        fullwidthSection: {
            transform: 'translate(0,0)',
            '&>$inner': {
                maxWidth: 'none',
            },
        },
        inner: {
            position: 'relative',
            maxWidth: theme.contentWidth,
            padding: theme.spacing(2),
            margin: '0 auto',
            height: '100vh',
            display: 'flex',
        },
        content: {
            pointerEvents: 'none',
            position: 'fixed',
            top: '50%',
            right: '50%',
            marginRight: 8,
            color: 'rgba(255,255,255,0.92)',
            textAlign: 'left',
            opacity: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
        },
        title: {
            textTransform: 'uppercase',
        },
        button: {
            color: 'inherit',
            border: '2px solid',
        },
        container: {
            position: 'relative',
            // transform: 'translate(0, 0)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            zIndex: 0,
            justifyContent: 'center',
        },
        centerItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
    {
        name: 'FullscreenSection',
    }
);
