import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
    theme => ({
        root: {
            position: "relative",
            margin: "0 auto",
            padding: theme.spacing(5),
            textAlign: 'center',
            maxWidth: '75vh',
        },
        wheel: {
            position: "relative",
            width: '1em',
            height: '1em',
            borderRadius: "50%",
            overflow: "hidden",
            // border: "8px solid #fff",
            boxShadow: "rgba(0,0,0,0.2) 0px 0px 10px, rgba(0,0,0,0.05) 0px 3px 0px",
            transform: "rotate(0deg)",

            '&::before': {
                content: "''",
                position: "absolute",
                boxShadow: "inset rgba(0,0,0,0.2) 0px 0px 4px 8px, rgba(0,0,0,0.05) 0px 3px 0px",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "50%",
                zIndex: 1,
                pointerEvents: 'none',
            },
            '&::after': {
                content: "''",
                position: "absolute",
                border: "8px solid white",
                top: '-4px',
                left: '-4px',
                right: '-4px',
                bottom: '-4px',
                borderRadius: "50%",
                zIndex: 2,
                boxShadow: "rgba(0,0,0,0.2) 0px 0px 10px, rgba(0,0,0,0.05) 0px 3px 0px",
                pointerEvents: 'none',
            },
        },
        wheelInner: {
            position: 'relative',
            width: "100%",
            height: "100%",
            // transition: "all 10s cubic-bezier(0,.99,.44,.99)",
            background: 'rgba(0,0,0,0.24)',
        },
        section: {
            position: "absolute",
            top: 0,
            right: 0,
            width: '50%',
            height: '50%',
            transformOrigin: '0% 100%',
            overflow: 'hidden'
        },
        spin: {
            position: 'absolute',
            // display: 'flex',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            borderRadius: '50%',
            boxShadow: "rgba(0,0,0,0.2) 0px 0px 10px, rgba(0,0,0,0.05) 0px 3px 0px",
            zIndex: 3,
            cursor: 'pointer',
            fontSize: '5%',

            '&:before': {
                content: '""',
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                fontSize: '150%',
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '0 0.4em 1em',
                borderColor: 'transparent transparent white transparent',
                transform: 'translate(-50%, 10%)'
            },
            '&:active': {
                '& $spinText': {
                    transform: 'scale(0.9)',
                },
                '& $spinCircleShadow': {
                    boxShadow: 'rgba(0,0,0,0.24) 0px 0px 5px inset',
                }
            }
        },
        spinText: {
            display: 'block',
            color: 'rgba(0,0,0,0.48)',
            fontWeight: 900,
            position: 'relative',
            padding: 5,
            margin: 5,
            zIndex: 1,
            textTransform: 'uppercase',
        },
        spinCircle: {
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            paddingBottom: '100%',
            borderRadius: '50%',
            background: 'white',

        },
        spinCircleShadow: {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 4,
            left: 4,
            bottom: 4,
            right: 4,
            borderRadius: '50%',
            boxShadow: 'rgba(255,255,255,1) 0px -1px 0px inset, rgba(255,255,255,1) 0px 1px 0px inset,  rgba(0,0,0,0.4) 0px 0px 5px',
            background: 'radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(234,234,234,1) 100%)',
            zIndex: -1,
        },

        '@keyframes blink': {
            '0%': {
                opacity: 0,
            },
            '20%': {
                opacity: 1,
            },
            '40%': {
                opacity: 0,
            },
            '60%': {
                opacity: 1,
            },
            '80%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        chip: {
            margin: theme.spacing(1),
            fontWeight: 'bolder',
            '&:hover, &:focus': {
                boxShadow: '0 0 8px 4px currentColor'
            }
        },
        chipWinner: {
            marginTop: theme.spacing(2),
        },
        chipWinnerShow: {
            animation: '2s $blink',
        },
        chipDisabled: {
            // opacity: 0.5,
            textDecoration: 'line-through',
        },
        chipIcon: {
            opacity: 0.5,
            color: 'inherit',
        },
        deleteIcon: {
            opacity: 0.5,        
            color: 'inherit',
            '&:hover': {
                color: 'inherit',
            }
        },
    }),
    {
        name: "WheelOfFortune",
    }
);
