import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
    theme => ({
        root: {
            flexGrow: 1,
            background: "transparent",
            color: "white",
            boxShadow: "none",
        },
        ul: {
            listStyleType: "none",
            display: "flex",
        },
        button: {
            color: "inherit",
            textTransform: "uppercase",
        },
        logo: {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            fontWeight: "bold",
            fontDisplay: "auto",
            fontFamily: "monospace, monospace",
            color: "rgba(255,255,255,0.75)",
        },
        logo__link: {
            color: "inherit",
            textDecoration: "none",
            "&:hover, &:active": {
                color: "inherit",
                textDecoration: "none",
            },
        },
        logo__mark: {
            marginRight: theme.spacing(0.5),
        },
        logo__text: {
            fontSize: "1.125rem",
            whiteSpace: 'pre',
        },
    }),
    {
        name: "Header",
    }
);
