import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FullscreenSection } from '../../components/FullscreenSection';

const styles = theme => {
    return {
        root: {
            width: '50%',
            fontFamily: 'sans-serif',
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 1,
            color: '#666',
            transform: 'translate(0, 0)',
            ...theme.no_highlights,
        },

        flowerWrapper: {
            maxWidth: '100%',
            margin: '0 auto',
            overflow: 'hidden',
            cursor: 'pointer',
            ...theme.no_highlights,
        },

        petal: {
            position: 'absolute',
            height: '35%',
            width: '35%',
            top: `${(100 - 35 * 2) / 2}%`,
            left: `${(100 - 35 * 2) / 2}%`,
            opacity: 0.2,
            borderTopRightRadius: '100%',
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: '100%',
            zIndex: 0,

            transformOrigin: '25% 125%',
            transition: 'all 1.5s',
            pointerEvents: 'none',

            '&:nth-child(odd)': {
                zIndex: 1,
            },
        },

        flower: {
            transform: 'rotate(0)',
            borderRadius: '50%',
            overflow: 'hidden',
            transition: 'all 0.75S',
            ...theme.no_highlights,
            // animation: 'rotateRainbow 10S infinite linear',

            '&:after': {
                content: "''",
                display: 'block',
                paddingBottom: '100%',
                borderRadius: 'inherit',
            },

            '&:hover': {
                '& > $petal': {
                    transition: 'all 1s',
                },
            },
            '&:active': {
                '& > $petal': {
                    opacity: 0.2,
                    transition: 'all 1s',
                },
            },
        },

        '@keyframes rotateRainbow': {
            from: { transform: 'rotate(0)' },
            to: { transform: 'rotate(360deg)' },
        },
    };
};

const RainbowFlower = props => {
    const numPetals = 36;
    const possibleStates = ['click', 'hover', 'none'];
    const [mouseState, setMouseState] = useState('none');
    const [isUsingTouch, setIsUsingTouch] = useState(false);
    const steps = 360 / numPetals;
    
    const { classes } = props;

    const setPetalState = useCallback((i) => {
        switch (mouseState) {
            case 'click':
                return {
                    transform: 'translateX(0%) translateY(100%) rotate(' + i * steps + 'deg) scale(1.35)',
                    transformOrigin: '100% 0%',
                };
            case 'hover':
                return {
                    transform: 'translateX(0) translateY(0) rotate(' + i * steps + 'deg) scale(1)',
                    transformOrigin: '100% 100%',
                };
            default:
                return {
                    transform: 'translateX(75%) translateY(-25%) rotate(' + i * steps + 'deg) scale(1.1)',
                    transformOrigin: '',
                };
        }
    }, [mouseState, steps]);

    const handleNextState = useCallback((e) => {
        setIsUsingTouch(true)
        
        setMouseState(prev => {
            const nextIndex = possibleStates.indexOf(prev) + 1;
            return possibleStates[nextIndex] ? possibleStates[nextIndex] : possibleStates[0];
        })
    }, [possibleStates]);

    const handleMouseEvent = (newState) => {
        if(isUsingTouch) {
            return;
        }
        setMouseState(newState)
    }

    return (
        <FullscreenSection id="RainbowFlower">
            <div id="RainbowFlower" className={classes.root}>
                <div className={classes.flowerWrapper}>
                    <div
                        className={classes.flower}
                        onMouseEnter={() => handleMouseEvent('hover')}
                        onMouseLeave={() => handleMouseEvent('none')}
                        onMouseDown={() => handleMouseEvent('click')}
                        onMouseUp={() => handleMouseEvent('hover')}
                        onTouchEnd={handleNextState}
                    >
                        {[...Array(numPetals)].map((petal, i) => {
                            return (
                                <div
                                    key={i}
                                    className={classes.petal}
                                    style={{
                                        background: `hsl(${steps * i}, 96%, 60%)`,
                                        ...setPetalState(i),
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </FullscreenSection>
    );
};

export default withStyles(styles)(RainbowFlower);
