import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
    theme => ({
        background: {
            background: "#FDD035",
            background: "linear-gradient(135deg, #FFE850 0%, #FFC21F 80%)",
        },
        root: {
            position: "relative",
            margin: "0 auto",
            padding: theme.spacing(5),
            textAlign: "center",
            maxWidth: "75vw",
        },
    }),
    {
        name: "BananaScale",
    }
);
