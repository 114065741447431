import { initialState, constants } from "./";

export const useActions = (state, dispatch) => {
    return {
        example(payload) {
            // console.log('useActions.example', payload);
            dispatch({
                type: "EXAMPLE",
                payload,
            });
        },
        reset(payload) {
            dispatch({
                type: "RESET",
                payload: initialState(payload),
            });
        },
    };
};
