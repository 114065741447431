import { makeStyles } from '@material-ui/styles';

const textColor = 'rgb(172, 255, 231)';
const inputColor = 'rgb(248, 174, 255)';
const errorColor = 'rgb(255, 137, 189)';

export const useStyles = makeStyles(
    theme => ({
        root: {
            fontFamily: 'Monospace',
            color: textColor,
            width: '95%',
            height: '500px',
            overflow: 'auto',
            padding: '1em',
            lineHeight: '16px',
            fontSize: '16px',
            whiteSpace: 'break-spaces',
            '&> p': {
                margin: theme.spacing(1, 0),
            },
        },
        lastLine: {
            color: inputColor,
        },
        input: {
            minWidth: 1,
            outline: '0px solid transparent',
            margin: 0,
            display: 'inline-block',
            verticalAlign: 'top',
            '&:focus + $cursor': {
                display: 'none',
            },
        },
        cursor: {
            display: 'inline-block',
            width: 8,
            height: '1em',
            // background: inputColor,
            border: `1px solid ${inputColor}`,
            borderRadius: 1,
            verticalAlign: 'bottom',
            // marginLeft: -8,
            // animation: '$cursor 1s infinite',
            // '@media (prefers-reduced-motion: reduce)': {
            //     '&__cursor': { animation: 'none' },
            // },
        },
        error: {
            color: errorColor,
        },
        // '@keyframes cursor': {
        //     '0%': { opacity: 0 },
        //     '50%': { opacity: 1 },
        //     '100%': { opacity: 0 },
        // },
    }),
    {
        name: 'CommandLine',
    }
);

export const useMessageStyles = makeStyles(
    theme => ({
        error: {
            color: errorColor,
        },
        success: {},
    }),
    {
        name: 'CliMessage',
    }
);
