import React, { useReducer, useContext, createContext, useEffect } from 'react';

import { useActions, reducer, initialState } from './';
import { useKonami } from '../helpers';

const WheelContext = createContext(null);

export const ContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState());
    const actions = useActions(state, dispatch);
    useKonami(() => actions.reset({ cheats: true }));

    useEffect(() => {
        // console.log("[state]: ", state);
    }, [state]);

    const value = {
        state,
        actions,
    };

    return <WheelContext.Provider value={value}>{props.children}</WheelContext.Provider>;
};

export const useWheelContext = () => useContext(WheelContext);
