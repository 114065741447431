import React, { useState, useRef } from "react";
import { useHistory, useLocation } from 'react-router';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import SettingsIcon from "@material-ui/icons/Settings";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import ResetIcon from "@material-ui/icons/Restore";
import { makeStyles } from "@material-ui/styles";

import { useWheelContext } from "../context";

const useStyles = makeStyles(
    theme => ({
        toggleButton: {
            color: "white !important",
        },
        hiddenInput: {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: 10,
            height: 0,
            pointerEvents: 'none',
        },
        ListItemIcon: {
            minWidth: 0,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(0.5),
        }
    }),
    {
        name: "Settings",
    }
);

export function Settings(props) {
    const classes = useStyles();
    const { state, actions } = useWheelContext();

    let history = useHistory();
    let location = useLocation();

    const clipboardInput = useRef(null)
    const [url, setUrl] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
    };
    
    const handleReset = () => {
        history.push({
            pathname: location.pathname,
        });
        actions.reset();
    };

    const copyUrlToClipboard = () => {
        const copyText = clipboardInput.current;

        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand("copy");
        setSnackbarOpen(true);
    }

    const handleShare = () => {
        const title = state.title;
        const names = state.sections.map(section => section.name);

        const searchParams = new URLSearchParams(location.search)
        searchParams.set("title", title);
        searchParams.set("names", names);

        history.push({
            pathname: location.pathname,
            search: searchParams.toString()
        });
        actions.reset();

        const newUrl = window.location.href
        setUrl(newUrl)

        setTimeout(() => {
            copyUrlToClipboard();
        }, 1)
    };

    return (
        <div>
            <Button
                aria-controls="settingMenu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.toggleButton}
                disabled={state.spinning}
            >
                <SettingsIcon />
            </Button>
            <Menu
                id="settingMenu"
                anchorEl={anchorEl}
                keepMounted
                open={open && !state.spinning}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                getContentAnchorEl={null}
            >
                <MenuItem onClick={handleShare}>
                    Share
                    <ListItemIcon className={classes.ListItemIcon}>
                        <>
                            <input
                                ref={clipboardInput}
                                className={classes.hiddenInput}
                                id="UrlClipboard"
                                defaultValue={url}
                            />
                            <CopyIcon fontSize="small" />
                        </>
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleReset}>
                    Reset
                    <ListItemIcon className={classes.ListItemIcon}>
                        <ResetIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} variant="filled" severity="success">
                    Copied URL to clipboard!
                </Alert>
            </Snackbar>
        </div>
    );
}
