import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
    theme => ({
        root: {
            width: '100%',
            color: 'rgba(255,255,255,0.8)',
        },
        buttonWrapper: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        combo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        comboList: {
            display: 'flex',
            listStyle: 'none',
        },
        comboItem: {},
        avatar: {
            borderRadius: 0,
            background: 'rgba(0,0,0,0.12)',
        },
        avatarActive: {
            background: 'rgba(0,0,0,0.24)',
            color: 'rgba(0,255,0,1)',
            textShadow: '0px 0px 5px rgba(0, 255, 0, 1)',
        },
        skipButton: {
            color: 'rgba(255,255,255,0.5)',
            textTransform: 'lowercase',
        },
    }),
    {
        name: 'Homepage',
    }
);
