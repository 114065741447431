import React, { useState, useEffect, useRef } from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const animDuration = 3000;

const styles = theme => {
    return {
        root: {
            height: '100%',
            width: '100%',
            margin: 0,
            fontFamily: 'Sans-serif',
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 1,
            color: '#666',
        },
        
        '@keyframes fadeTech': {
            from: {opacity: 0.2},
            to: {opacity: 0},
        },
        '@keyframes growTech': {
            from: {
                transform: 'translate(-50%,-50%) scale(0) rotate(0deg)'
            },
            to: {
                transform: `translate(-50%,-50%) scale(1) rotate(${360*4}deg)`
            },
        },
        
        stairway: {
            width: '100%',
            transition: `all ${animDuration}ms linear`,
            
            '&:before': {
                content: '""',
                display: 'block',
                paddingBottom: '100%',
            },
        },
        
        step: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            opacity: 1,
            boxShadow: `inset 0 0 0 1px rgba(255,255,255,0.12)`,
            transition: `all ${animDuration}ms linear`,
            animation: `growTech ${animDuration}ms linear`
        },
    }
};


const Settings = (props) => {
    
    const [ animState, setAnimState ] = props.anim;
    
    const handleAnimDirection = (e) => {
        setAnimState(e.target.checked ? '1' : '-1');
    }
    
    return (
        <FormGroup 
            row
            style={{
                justifyContent: 'space-between',
                marginTop: 16,
            }}
            >
            
            <FormControlLabel
                control={
                    <Switch
                        checked={animState === '1'}
                        onChange={e => handleAnimDirection(e)}
                        value="animState"
                        color="primary"
                        />
                }
                label="animation"
            />
        </FormGroup>
    );
}

const _Stairway = (props => {
    const [ sizes, setSizes ] = useState([]);
    const [ ratio, setRatio ] = useState(0);
    const [ time, setTime ] = useState(0);
    const [ aarray, setAarray ] = useState([]);
    
    const {
        parentRef,
        animState
    } = props;
    
    useEffect(()=> {
        let _sizes = [];
        
        let initialWidth = parentRef.current.offsetWidth * 0.8;
        let numSizes = 0;
        let _array = [];
        while( initialWidth > 4 && numSizes < 10) {
            _sizes.push(initialWidth)
            _array.push(numSizes)
            numSizes = numSizes + 1;
            initialWidth *= 0.863; // magic number
        }
        setSizes(_sizes)
        setRatio(360 / numSizes / 6)
        setAarray(_array)
    }, [parentRef]);
    
    
    useEffect(()=> {
        
        const changeArray = () => {
            if ( document.hidden ) {
                return;
            }
            
            let copy = new Array(...aarray);
            copy.push(copy[copy.length-1] === aarray.length ? 0 : copy[copy.length-1] + 1)
            copy.splice(0,1);
            if ( copy.length === aarray.length ) {
                setAarray(copy)
                if ( time === 36 ) {
                    setTime(0)
                    setTime(1)
                } else {
                    setTime(time + 1 * animState)
                }
            }
        }
        const colorTimeout = setInterval(changeArray, animDuration);
        
        return () => {
            clearInterval(colorTimeout)
        }
    }, [aarray, time]);
    
    
    
    const {classes} = props;
    
    return (
        <div 
            className={classes.stairway}
            >
            { aarray.map( (step, i) => {
                return (
                    <div 
                        key={step}
                        id={step}
                        className={classes.step}
                        style={{
                            width: sizes[0],
                            height: sizes[0],
                            background: `hsl(${ratio*i}, 90%, 50%)`,
                            opacity: Math.min(i * 0.1 , 0.5),
                            animationDuration: `${Math.max(0 + i, animDuration * 10)}ms`,
                        }}
                        />
                )
            })}
        </div>
    )
})
const Stairway = withStyles(styles)(_Stairway);

const Technicolor2 = (props) => {
    const parentRef = useRef(null);
    const [ animState, setAnimState ] = useState('1');
    
    const {classes} = props;
    
    return (
        <div 
            id={props.id}
            className={classes.root}
            ref={parentRef}
            >
            
            <Stairway 
                parentRef={parentRef}
                animState={animState}
                />
            <Settings 
                anim={[animState, setAnimState]}
                />
        </div>
    );
}


export default withStyles(styles)(Technicolor2);
