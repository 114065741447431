import React, { useState, useEffect, useRef } from 'react';

import { FullscreenSection } from '../../components/FullscreenSection';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
    return {
        root: {
            width: '50%',
            maxWidth: 500,
            margin: 0,
            fontFamily: 'Sans-serif',
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 1,
            color: '#666',

            display: 'grid',
            gridAutoRows: '1fr',

            gridGap: '4px',
            cursor: 'pointer',

            '&::before': {
                content: '""',
                width: 0,
                paddingBottom: '100%',
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
            },
            '& > *:first-child': {
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
            },
        },
        squareWrapper: {
            display: 'flex',
        },
        square: {
            flex: 1,
            borderRadius: '50%',
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    };
};

const _Square = props => {
    const { id, classes } = props;

    const handleChangeState = type => {
        props.handleChangeState(type, id);
    };

    return (
        <div
            id={id}
            className={classes.squareWrapper}
            onMouseEnter={e => handleChangeState('mouseEnter')}
            onMouseDown={e => handleChangeState('mouseDown')}
            onMouseUp={e => handleChangeState('mouseUp')}
        >
            <div
                className={classes.square}
                style={{
                    backgroundColor: props.color,
                    transform: `translate(${props.translate[0]}px,${props.translate[1]}px)  scale(${props.scale})`,
                    transitionDuration: `${props.duration}ms`,
                    transitionDelay: `${props.delay}ms`,
                }}
            ></div>
        </div>
    );
};
const Square = withStyles(styles)(_Square);

const Squares = props => {
    const parentRef = useRef(null);
    // const [ numColors, setNumColors ] = useState(7);
    const numColors = 7;
    const [gridState, setGridState] = useState({});
    const [initScale, setInitScale] = useState(1);
    const [hoverId, setHoverId] = useState(null);

    // const [ aReach, setReach ] = useState(7);
    const aReach = 7;

    const colors = ['#612E8D', '#1474BC', '#00A396', '#80C540', '#F5B52E', '#EC5B35', '#E9225E', '#C12286'];

    // animation settings
    const aDuration = 100;
    const aScale = 0.1;
    const minScale = 0.5;

    const restState = {
        scale: minScale,
        translate: [0, 0],
        duration: aReach * aDuration,
    };

    useEffect(() => {
        let _squares = {};
        for (var r = 0; r < numColors; r++) {
            for (var c = 0; c < numColors + 0; c++) {
                _squares[`${r}-${c}`] = {
                    id: `${r}-${c}`,
                    color: colors[c + r < colors.length ? c + r : c + r - colors.length],
                    ...restState,
                };
            }
            setGridState(_squares);
        }
    }, []);

    useEffect(() => {
        if (!hoverId) return;

        const split = hoverId.split('-');
        const hoverY = parseInt(split[0]);
        const hoverX = parseInt(split[1]);

        const getOffset = (h, k) => {
            return h === k ? 0 : k > h ? k - h - aReach : aReach - (h - k);
        };

        let newState = Object.entries(gridState).reduce((accumulator, [key, value]) => {
            const splitKey = key.split('-');
            const keyY = parseInt(splitKey[0]);
            const keyX = parseInt(splitKey[1]);

            const hypot = Math.hypot(Math.abs(keyY - hoverY) + Math.abs(keyX - hoverX));

            switch (true) {
                case key === hoverId:
                    accumulator[key] = {
                        ...value,
                        scale: initScale,
                        translate: [0, 0],
                        duration: 100,
                        delay: 0,
                    };
                    break;
                case hypot > aReach:
                    accumulator[key] = {
                        ...value,
                        ...restState,
                    };
                    break;
                default:
                    accumulator[key] = {
                        ...value,
                        scale: Math.max(initScale - (10 / aReach) * hypot * aScale, minScale),
                        duration: 50 + hypot * aDuration,
                        translate: [
                            getOffset(hoverX, keyX) * Math.abs(1 / hypot),
                            getOffset(hoverY, keyY) * Math.abs(1 / hypot),
                        ],
                    };
            }
            return accumulator;
        }, {});
        setGridState(newState);
    }, [initScale, hoverId]);

    const { classes } = props;

    const handleChangeState = (type, hoverId) => {
        setHoverId(hoverId);

        if (type === 'reset') {
            let resetState = Object.entries(gridState).reduce((accumulator, [key, value]) => {
                accumulator[key] = {
                    ...value,
                    ...restState,
                };
                return accumulator;
            }, {});
            setGridState(resetState);
        } else {
            if (type === 'mouseDown') {
                setInitScale(1.25);
            } else if (type === 'mouseUp') {
                setInitScale(0.5);
                setTimeout(function () {
                    setInitScale(1);
                }, 100);
            }
        }
    };

    return (
        <FullscreenSection id="Bubbles">
            <div
                id={props.id}
                className={classes.root}
                style={{
                    gridTemplateColumns: `repeat(auto-fill, minmax(${100 / (numColors + 1)}%, 1fr))`,
                }}
                ref={parentRef}
                onMouseLeave={() => handleChangeState('reset')}
            >
                {Object.keys(gridState).map((square, i) => {
                    const { id, color, scale, translate, duration, delay } = gridState[square];
                    return (
                        <Square
                            key={id}
                            id={id}
                            scale={scale}
                            translate={translate}
                            duration={duration}
                            delay={delay}
                            color={color}
                            handleChangeState={(type, i) => handleChangeState(type, i)}
                        />
                    );
                })}
            </div>
        </FullscreenSection>
    );
};

export default withStyles(styles)(Squares);
