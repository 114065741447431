import { constants } from "./";

const exampleAction = (state, payload) => {
    return state;
};

export const reducer = (state, action) => {
    // console.log(state, action);

    switch (action.type) {
        case "RESET":
            return action.payload;

        case "SHARE":
            return state;

        default:
            return state;
    }
};
