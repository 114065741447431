import React from 'react';
import { useStyles } from './styles';

import { useWheelContext } from '../context';

export const Section = props => {
    const classes = useStyles();
    const { actions } = useWheelContext();

    const {
        i,
        color,
        numSections
    } = props;

    
    const transform = () => {
        if (numSections === 1) {
            return `rotate(0deg) scale(2) translate(-50%, 50%)`
        }
        else if (numSections === 2) {
            return `rotate(${180 * i}deg) scale(2) translate(0, 50%)`
        }
        else if (numSections === 3) {
            return `rotate(${120 * i}deg) skewY(30deg) scale(1.5)`
        }
        else if (numSections > 3) {
            const sliceAngle = numSections > 3 ? 360 / numSections : 90;
            return `rotate(${sliceAngle * i}deg) skewY(${(90 - sliceAngle) * -1}deg)`
        }


    }
    
    return (
        <div
            id={props.name}
            className={classes.section}
            style={{
                transform: transform(),
                background: color,
            }}
            onMouseEnter={() => actions.currentHover(props)}
            onMouseLeave={() => actions.currentHover(null)}
        />
    )
}