import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import convertUnits from 'convert-units';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoIcon from '@material-ui/icons/HelpOutline';

import ListSubheader from '@material-ui/core/ListSubheader';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';

const bananaConvertionMap = {
    cm: 13,
    in: 7,
    g: 118,
};

const unitTypeMap = {
    length: 'Length',
    mass: 'Mass',
    bmi: 'BMI',
};

const CustomAvatar = ({ label, background = 'default' }) => {
    const classes = useStyles();

    return (
        <Avatar
            sizes="small"
            classes={{
                root: clsx(classes.avatar, classes[`avatar__${background}`]),
            }}
        >
            {label}
        </Avatar>
    );
};

const unitsMap = {
    length: convertUnits()
        .list('length')
        .filter(u => !['ft-us', 'yd', 'mm'].includes(u.abbr)),
    mass: convertUnits()
        .list('mass')
        .filter(u => !['mcg', 'mg'].includes(u.abbr)),
};

export const UnitsSelection = ({ value, setValue, setType, open, setOpen }) => {
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = useCallback((unit, type) => {
        setType(type);
        setValue(unit);
        handleClose();
    }, []);

    const id = 'unitsModal';
    return (
        <>
            <IconButton
                aria-label="Select unit"
                aria-controls={id}
                aria-haspopup="true"
                onClick={handleOpen}
                className={classes.iconButton}
            >
                <CustomAvatar label={value} background="transparent" size="small" />
            </IconButton>
            <Dialog
                id={id}
                data-testid="dialog"
                open={open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                fullWidth={true}
                maxWidth="xs"
                onClose={handleClose}
            >
                <DialogTitle id="dialog-title">Convert from</DialogTitle>
                {Object.keys(unitsMap).map((type, i) => {
                    const unitsArray = unitsMap[type];

                    return (
                        <React.Fragment key={type}>
                            <Divider />
                            <List
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader
                                        component="div"
                                        id="nested-list-subheader"
                                        className={classes.ListSubheader}
                                    >
                                        {unitTypeMap[type]}
                                    </ListSubheader>
                                }
                            >
                                {unitsArray.map(unitData => {
                                    const { abbr, plural: label } = unitData;
                                    return (
                                        <ListItem
                                            key={abbr}
                                            button
                                            selected={abbr === value}
                                            onClick={() => handleChange(abbr, type)}
                                            classes={{
                                                root: classes.listemItem,
                                                selected: classes.listemItemSelected,
                                            }}
                                        >
                                            <ListItemText primary={label} />
                                            <ListItemIcon>
                                                <CustomAvatar label={abbr} />
                                            </ListItemIcon>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </React.Fragment>
                    );
                })}
            </Dialog>
        </>
    );
};

const useSelectStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '50%',
        textAlign: 'left',
    },
}));

export const Calculator = props => {
    const classes = useStyles();
    const [openUnitSelection, setOpenUnitSelection] = useState(false);
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const [units, setUnits] = useState('cm');
    const [type, setType] = useState('length');
    const [showDesc, setShowDesc] = useState(null);

    const averageBananaChart = {
        length: 17.8,
        mass: 118,
    };
    const defaultUnit = {
        length: 'cm',
        mass: 'g',
    };

    const roundToOneDec = val => Math.round(val * 10) / 10;

    useEffect(() => {
        if (!input || !units || !type) {
            setOutput(false);
            return;
        }

        // first convert to standard unit
        let outputValue = convertUnits(input)
            .from(units)
            .to(defaultUnit[type]);

        // now calculate number of bananas
        outputValue = outputValue / averageBananaChart[type];

        // change output if number too big or under zero
        let newOutput = outputValue;
        if (outputValue < 0) {
            newOutput = `you've got no bananas mate`;
        } else if (outputValue < 1000) {
            newOutput = `${roundToOneDec(outputValue)} 🍌`;
        } else if (outputValue > 100000000) {
            newOutput = [`A fucking shitload \n 🍌🍌🍌`];
        } else if (outputValue > 100000) {
            newOutput = `${roundToOneDec(outputValue / 100000)} million 🍌`;
        } else if (outputValue > 1000) {
            newOutput = `${roundToOneDec(outputValue / 1000)} thousand 🍌`;
        }

        setOutput(newOutput);
    }, [input, units, type]);

    const handleChangeInput = useCallback(e => {
        const value = e.target.value;
        setInput(value);
    }, []);

    const toggleDesc = () => {
        setShowDesc(prev => !prev);
    };

    const getScale = () => {
        const defaultAverage = averageBananaChart[type];

        const scale = convertUnits(defaultAverage)
            .from(defaultUnit[type])
            .to(units);

        return scale;
    };

    return (
        <Paper elevation={4} className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4" component="h1">
                    Banana for Scale
                </Typography>
                <IconButton onClick={toggleDesc} size="small" className={classes.infoButton}>
                    <InfoIcon size="small" />
                </IconButton>

                <Collapse in={!!showDesc}>
                    <Typography variant="caption" component="p" align="left" className={classes.infoDesc}>
                        Introducing the Banana Scale (BS) - the new Universal Measurement System created by humanity's
                        top minds and scientists - this system was designed to put an end to the confusion and
                        misunderstandings that often arise from using the inferior and obsolete metric and imperial
                        systems.
                    </Typography>
                    <Typography gutterBottom variant="caption" component="p" align="left" className={classes.infoDesc}>
                        Use this app to convert archaic measurement units such as <i>cm</i> or <i>ft</i> to the
                        universal bananas.
                    </Typography>
                </Collapse>
            </div>

            <Divider />
            <div className={classes.inputWrapper}>
                <InputBase
                    placeholder="value to convert"
                    value={input}
                    onChange={handleChangeInput}
                    classes={{
                        root: classes.input__root,
                        input: classes.input__input,
                    }}
                    type="number"
                    disabled={false}
                    fullWidth
                />

                <UnitsSelection
                    value={units}
                    setValue={setUnits}
                    open={openUnitSelection}
                    setOpen={setOpenUnitSelection}
                    setType={setType}
                />
            </div>

            <Collapse in={!!output}>
                <Divider />
                <div className={classes.outputWrapper}>
                    <Typography variant="h1" component="h1" className={classes.output}>
                        {output}
                    </Typography>
                </div>

                <Typography gutterBottom className={classes.outputLegend} variant="caption" align="right">
                    <span aria-label="banana" role="img">
                        (1🍌
                    </span>
                    {` ≈ ${getScale()}${units})`}
                </Typography>
            </Collapse>
        </Paper>
    );
};
