import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';

import { Context } from '../context';

const mainRed = '#F75578';
const strongRed = '#EA0A41';
const coolBlue = '#70EBFA';

const useStyles = makeStyles(
    theme => ({
        root: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            alignItems: 'start',
        },
        SpaceStyledControlsRoot: {
            margin: 0,
            padding: theme.spacing(1, 2),
            cursor: 'default',
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.simple,
            }),
            '&:hover': {
                background: 'rgba(0,0,0,0.1)',
                '& $controlRoot': {
                    opacity: 1,
                },
            },
        },
        formControl: {},
        group: {
            margin: theme.spacing(1, 0),
        },
        legend: {
            color: coolBlue,
            textTransform: 'uppercase',
            fontFamily: 'Nunito, Roboto, Helvetica, Arial, sans-serif',
            paddingTop: theme.spacing(1),
        },
        controlRoot: {
            margin: 0,
            opacity: 0.5,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.simple,
            }),
        },
        controlRootSelect: {
            opacity: 1,
            margin: 0,
        },
        controlLabel: {
            color: 'rgba(255,255,255,.84)',
            fontWeight: '800',
            fontFamily: 'Nunito, Roboto, Helvetica, Arial, sans-serif',
            // textTransform: "uppercase",
            fontSize: '0.8rem',
            letterSpacing: 0.5,
            lineHeight: 1,
        },
        radio: {
            color: 'rgba(255,255,255,.84)',
        },
        radioChecked: {},
        input: {
            top: 0,
            left: 0,
            width: '100%',
            cursor: 'inherit',
            height: '100%',
            margin: 0,
            opacity: 0,
            padding: 0,
            position: 'absolute',
        },
        CustomRadioRoot: {
            position: 'relative',
            display: 'flex',
            padding: theme.spacing(1),
            flex: '0 0 auto',
        },
        CustomRadioRootIcon: {
            position: 'relative',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fontSize: 8,

            '&::before': {
                content: "''",
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 8,
                width: '1em',
                height: '1em',
                border: `2px solid #979797`,
                borderRadius: '50%',
                transition: theme.transitions.create(['all'], {
                    duration: theme.transitions.duration.simple,
                }),
            },
            '&::after': {
                content: "''",
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 0,
                width: '1em',
                height: '1em',
                background: strongRed,
                borderRadius: '50%',
                opacity: 0,
                transition: theme.transitions.create(['all'], {
                    duration: theme.transitions.duration.simple,
                }),
            },
        },
        CustomRadioRootIconChecked: {
            '&::before': {
                color: 'transparent',
            },
            '&::after': {
                opacity: 1,
                fontSize: 8,
                boxShadow: `0 0 4px 2px ${strongRed}, 0 0 3px 0px ${strongRed}`,
            },
        },
    }),
    { name: 'controls' }
);

const CustomRadio = props => {
    const classes = useStyles();
    return (
        <div className={classes.CustomRadioRoot}>
            <div
                className={clsx(classes.CustomRadioRootIcon, props.checked ? classes.CustomRadioRootIconChecked : '')}
            />
            <input
                readOnly
                className={classes.input}
                type="radio"
                name={props.name}
                value={props.value}
                checked={props.checked}
            />
        </div>
    );
};

const SpaceStyledControls = props => {
    const classes = useStyles();
    return (
        <div className={classes.SpaceStyledControlsRoot}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel classes={{ root: classes.legend, focused: classes.legend }} component="legend">
                    {props.id}
                </FormLabel>
                <RadioGroup
                    aria-label={props.id}
                    name={props.id}
                    className={classes.group}
                    value={props.value}
                    onChange={props.onChange}
                >
                    {props.controls.map(label => (
                        <FormControlLabel
                            key={label}
                            classes={{
                                root: clsx(classes.controlRoot, props.value === label ? classes.controlRootSelect : ''),
                                label: classes.controlLabel,
                            }}
                            value={label}
                            label={label}
                            onClick={props.onChange}
                            control={
                                <CustomRadio name={props.id} value={label} checked={props.value === label} />
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

let Controls = props => {
    const classes = useStyles();
    const { scaleMode, setScaleMode } = useContext(Context);
    const { zoom, setZoom } = useContext(Context);

    return (
        <div className={classes.root}>
            <SpaceStyledControls
                id="scale"
                value={scaleMode}
                onChange={event => setScaleMode(event.target.value)}
                controls={['none', 'size', 'distance']}
            />
            <SpaceStyledControls
                id="zoom"
                value={zoom}
                onChange={event => setZoom(event.target.value)}
                controls={['-', '+']}
            />
        </div>
    );
};

export default Controls;
