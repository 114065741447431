import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Planet, colors } from "./index";

// import {
//     planetData,
// } from '../data'

import { Context } from "../context";

const useStyles = makeStyles(
    theme => ({
        solarSystem: {
            width: "1em",
            height: "1em",
            position: "fixed",
            top: "50%",
            left: "50%",
        },

        sun: {
            position: "absolute",
            fontSize: "1em",
            borderRadius: "50%",
            width: "1em",
            height: "1em",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: colors.$gold,
            boxShadow: "inset -0.05em -0.05em 0.3em 0em #FB7209",
            transition: "all 0.2s ease-in-out",
        },
    }),
    { name: "solarSystem" }
);

export default props => {
    const { renderData, availableSpace, sunSize, scale, zoom } = useContext(
        Context
    );

    const classes = useStyles();

    return (
        <div
            className={classes.solarSystem}
            style={{
                transform: "translate(-50%, -50%)",
                marginLeft: zoom === "-" ? 0 : "-50vw",
                transition: "margin 0.2s ease-in-out 0s",
            }}
        >
            <div
                style={{
                    fontSize: (zoom === "-" ? 1 : 4) * scale,
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    className={classes.sun}
                    style={{
                        fontSize: `${sunSize}em`,
                    }}
                />

                {renderData.map((planet, i) => {
                    return (
                        <Planet
                            key={planet.name}
                            sunSize={sunSize}
                            availableSpace={availableSpace}
                            index={i}
                            {...planet}
                        />
                    );
                })}
            </div>
        </div>
    );
};
