import React, { useReducer, useContext, createContext, useEffect } from 'react';

import { useActions, reducer, initialState } from './';

const Context = createContext(null);

export const ContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState());
    const actions = useActions(state, dispatch);

    useEffect(() => {
        // console.log("[state]: ", state);
    }, [state]);

    const value = {
        state,
        actions,
    };

    return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const useCalculatorContext = () => useContext(Context);
