import React from "react";

import { withStyles } from "@material-ui/core/styles";

import tinycolor from "tinycolor2"; // https://github.com/bgrins/TinyColor

import {
    Stars,
    Planets,
    Controls,
    colors,
} from "./components";

import { ContextProvider } from "./context";

const styles = theme => ({
    '@import': [
        "url('https://fonts.googleapis.com/css2?family=Nunito&display=swap')"
    ],
    root: {
        fontFamily: 'Nunito',
    },
    background: {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        background: colors.$blue300,
        zIndex: 0,

        "&:after": {
            content: '""',
            display: "block",
            background: `radial-gradient(circle, transparent, ${tinycolor(
                colors.$blue200
            ).setAlpha(0.2)})`,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
});

let SolarSystem = props => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <ContextProvider>
                <div className={classes.background} />
                <Controls />
                {React.useMemo(() => <Stars />, [])}

                <Planets />
            </ContextProvider>
        </div>
    );
};

export default withStyles(styles)(SolarSystem);
