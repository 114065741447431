
import { constants } from './';

export const initialState = (props = {}) => {
    
    const query = new URLSearchParams(window.location.search);
    const paramNames = query.get("names");
    const paramTitle = query.get("title");

    const _names = props.cheats ? [
        'Tiago',
        'Matt H',
        'Andy Ch',
        'Siri',
        'Joe',
        'Phillip',
        'Jimmy',
        'Dominic',
        'Andy Co',
        'Anthony',
        'Lucinda',
        'Ioan',
        'Matt B',
        'Chris',
    ].sort() : [];

    let names = [];

    if (paramNames) {
        const _names = paramNames.split(',');
        if (_names.length) {
            names = _names;
        }
    }
    if (!names.length) {
        names = _names;
    }

    let title = paramTitle ? paramTitle : 'Spin the Wheel!'

    const availableColors = [...constants.mColors];

    return {
        size: 250,
        rotation: 0,
        sections: names.map((name, i) => {
            const color = availableColors.splice(0, 1);
            return {
                id: i,
                color,
                name,
                hide: false,
            }
        }),
        availableColors,
        winner: false,
        currentHover: null,
        spinning: false,
        title,
    }
}