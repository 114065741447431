import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
    theme => {
        return {
            root: {
                position: 'relative',
                margin: '0 auto',
                padding: theme.spacing(2),
                textAlign: 'center',
                maxWidth: 500,
                width: '100%',
            },
            header: {
                margin: theme.spacing(2),
            },
            inputWrapper: {
                display: 'flex',
                padding: theme.spacing(4, 1),
                alignItems: 'center',
            },
            input__root: {
                ...theme.typography.h4,
                margin: theme.spacing(0.5),
                background: 'rgba(0,0,0,0.04)',
            },
            input__input: {
                padding: theme.spacing(1, 2),
            },
            outputWrapper: {
                padding: theme.spacing(4),
            },
            output: {
                display: 'inline-flex',
                alignItems: 'center',
                fontWeight: 900,
                whiteSpace: 'break-spaces',
            },
            outputLegend: {
                display: 'block',
                color: 'rgba(0,0,0,0.64)',
            },
            infoButton: {
                position: 'absolute',
                top: 0,
                right: 0,
                margin: theme.spacing(1),
                opacity: 0.7,
            },
            infoDesc: {
                margin: theme.spacing(2, 1),
            },

            // unit selection
            iconButton: {
                background: '#FFC21F',
                color: 'white',
                padding: theme.spacing(0.5),
                margin: theme.spacing(0.5),

                '&:hover, &:focus': {
                    background: '#FFC21F',
                    color: 'white',
                },
            },
            avatar: {
                fontSize: 14,
                height: '2.5em',
                width: '2.5em',
                fontWeight: 900,
            },
            avatar__primary: {
                background: 'none',
                color: '#FFC21F',
            },
            avatar__transparent: {
                background: 'none',
                color: 'inherit',
            },
            avatar__default: {
                background: 'rgba(0,0,0,0.06)',
                color: 'rgba(0,0,0,0.64)',
            },
            ListSubheader: {
                background: 'white',
            },
            listemItem: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                fontWeight: 'bold',
                '&$listemItemSelected': {
                    background: '#FFC21F',
                },
            },
            listemItemSelected: {},
        };
    },
    {
        name: 'Calculator',
    }
);
