import React from 'react';

import Grid from '@material-ui/core/Grid';

import { FullscreenSection } from '../../components/FullscreenSection';
import { ContextProvider } from './context';

import {
    // Settings,
    WheelContent,
    PeopleList,
    Title,
} from './components';

export const WheelOfFortune = props => {
    return (
        <FullscreenSection id="Randomizer">
            <ContextProvider>
                <Grid container spacing={2} style={{ paddingTop: 64, maxWidth: 1100, margin: '0 auto', height: '100%' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{position: 'relative'}}>
                            <Title />
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item ld={6} md={3} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <PeopleList />
                            </Grid>
                            <Grid item ld={6} md={9} xs={12}>
                                <WheelContent />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContextProvider>
        </FullscreenSection>
    );
};
