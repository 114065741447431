import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { useStyles } from "./styles";

const pages = [
    // {
    //     name: 'Work',
    //     link: '/',
    // },
    // {
    //     name: 'Resume',
    //     link: '/resume',
    // },
    // {
    //     name: 'Contact',
    //     link: '/contact',
    // }
];

export function Header(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    if (props.hide) return null;

    return (
        <AppBar position="fixed" color="inherit" className={classes.root}>
            <Toolbar>
                <span className={classes.logo}>
                    {/* <span className={classes.logo__mark}>{`>`}</span> */}
                    <Link
                        className={classes.logo__link}
                        color="inherit"
                        aria-label="Menu"
                        to="/"
                    >
                        <span className={classes.logo__text}>@freetosteal</span>
                    </Link>
                    <span className={classes.logo__text}>{pathname === '/' ? '/hello-world' : pathname}</span>
                </span>

                <ul className={classes.ul}>
                    {pages.map(page => {
                        return (
                            <li key={page.name} className={classes.li}>
                                <Button
                                    to={page.link}
                                    className={classes.button}
                                >
                                    {page.name}
                                </Button>
                            </li>
                        );
                    })}
                </ul>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object,
};
