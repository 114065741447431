
import { initialState, constants } from './';

const { minRotation, rotationDuration } = constants;

export const useActions = (state, dispatch) => {

    return {
        example(payload) {
            // console.log('useActions.example', payload);
            dispatch({
                type: 'EXAMPLE',
                payload,
            });
        },
        spin(payload) {
            // console.log('useActions.spin', payload);
            dispatch({
                type: 'SPIN',
                payload,
            });

            setTimeout(() => {
                dispatch({
                    type: 'STOP_SPINNING',
                    rotation: state.rotation - minRotation,
                    payload,
                });
            }, rotationDuration);
        },
        currentHover(payload) {
            // console.log('useActions.currentHover', payload);
            dispatch({
                type: 'CURRENT_HOVER',
                payload,
            });
        },
        toggleSection(payload) {
            // console.log('useActions.removeSection', payload);
            dispatch({
                type: 'TOGGLE_SECTION',
                payload,
            });
        },
        reset(payload) {
            dispatch({
                type: 'RESET',
                payload: initialState(payload)
            })
        },
        personAdd(payload) {
            dispatch({
                type: 'PERSON_ADD',
                payload: {
                    name: payload,
                    hide: false,
                }
            })
        },
        personRemove() {
            dispatch({
                type: 'PERSON_REMOVE',
            })
        },
        setTitle(payload) {
            dispatch({
                type: 'SET_TITLE',
                payload
            })
        },
        share() {
            dispatch({
                type: 'SHARE',
            })
        },
    }
}